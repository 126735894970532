import React from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Box,
  Grid,
} from "@mui/material";
import { useTheme } from "../../ThemeContext";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import lightLogo from "../../Assets/white logo.png";
import darkLogo from "../../Assets/white logo dark.png";

const SideMenu = ({ user }) => {
  const { darkMode } = useTheme();
  return (
    <Grid
      sx={{
        width: 300,
        flexShrink: 0,
        boxSizing: "border-box",
      }}
    >
      <List>
        <ListItem>
          <img
            src={darkMode ? lightLogo : darkLogo}
            alt="Locaided"
            style={{
              width: "50%",
              height: "auto",
            }}
          />
        </ListItem>
        <ListItem button component={Link} to="/dashboard">
          <ListItemIcon>
            <HomeOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/dashboard/chats">
          <ListItemIcon>
            <MailOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Messages" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <BoltOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Flash Point" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <VerifiedOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Premium" />
        </ListItem>
        <ListItem button component={Link} to="/dashboard/contacts">
          <ListItemIcon>
            <ContactMailOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Contacts" />
        </ListItem>
        <ListItem button component={Link} to="/dashboard/settings">
          <ListItemIcon>
            <SettingsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
        <ListItem button component={Link} to="/dashboard/profile">
          <ListItemIcon>
            <Avatar
              alt="User"
              src={user.profile_image_url.sharable}
              style={{
                alignItems: "center",
                width: "35px",
                height: "auto",
              }}
            />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItem>
      </List>
      <Box sx={{ p: 2 }}>
        <Button
          variant="contained"
          sx={{
            width: "75%",
            backgroundColor: "#ff2557",
            borderRadius: "50px",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#e0224f",
            },
          }}
        >
          Post
        </Button>
      </Box>
    </Grid>
  );
};

export default SideMenu;
