import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  TextField,
  Typography,
  Slider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
} from "@mui/material";
import {
  Search as SearchIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import LaunchIcon from "@mui/icons-material/Launch";

const LiveFeedFilter = () => {
  const [radius, setRadius] = useState(10);
  const [trendingLocations, setTrendingLocations] = useState([]);
  const [trendLocationPosts, setTrendLocationPosts] = useState([]);
  const [loadingTrending, setLoadingTrending] = useState(true); // Loading for trending locations
  const [loadingRadius, setLoadingRadius] = useState(true); // Loading for radius

  const marks = [
    { value: 1, label: "1" },
    { value: 3, label: "3" },
    { value: 5, label: "5" },
    { value: 7, label: "7" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 30, label: "30" },
    { value: 50, label: "50" },
  ];

  useEffect(() => {
    const accessToken = localStorage.getItem("access-token");
    const tokenType = localStorage.getItem("token-type");
    const client = localStorage.getItem("client");
    const expiry = localStorage.getItem("expiry");
    const uid = localStorage.getItem("uid");

    axios
      .get("https://api.locaided.com/v1/users/cities_with_most_records", {
        headers: {
          "access-token": accessToken,
          "token-type": tokenType,
          client: client,
          expiry: expiry,
          uid: uid,
        },
      })
      .then((response) => {
        const cities = response.data.data || [];
        setTrendingLocations(cities.nearest_cities.slice(0, 4));
        setTrendLocationPosts(cities.trending_cities.slice(0, 4));
        setLoadingTrending(false);
      })
      .catch((error) => {
        console.error("Error fetching trending cities:", error);
        setLoadingTrending(false);
      });

    axios
      .get("https://api.locaided.com/v1/users/get_radius", {
        headers: {
          "access-token": accessToken,
          "token-type": tokenType,
          client: client,
          expiry: expiry,
          uid: uid,
        },
      })
      .then((response) => {
        setRadius(response.data.data.radius || 10);
        setLoadingRadius(false);
      })
      .catch((error) => {
        console.error("Error fetching radius:", error);
        setLoadingRadius(false);
      });
  }, []);

  const handleRadiusChange = (e, newValue) => {
    if (newValue !== null) {
      setRadius(newValue);
      const accessToken = localStorage.getItem("access-token");
      const tokenType = localStorage.getItem("token-type");
      const client = localStorage.getItem("client");
      const expiry = localStorage.getItem("expiry");
      const uid = localStorage.getItem("uid");

      axios
        .patch(
          "https://api.locaided.com/v1/users/update_radius",
          { radius: newValue },
          {
            headers: {
              "access-token": accessToken,
              "token-type": tokenType,
              client: client,
              expiry: expiry,
              uid: uid,
            },
          }
        )
        .then((response) => console.log("Radius updated:", response.data))
        .catch((error) => console.error("Error updating radius:", error));
    }
  };

  if (loadingTrending || loadingRadius) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <CircularProgress size={50} sx={{ color: "#FD445D" }} />
        <Typography variant="h6" style={{ marginTop: "18px" }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <Box p={2} sx={{ width: 360 }}>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Typography variant="h6" gutterBottom>
          Live Feed Filter
        </Typography>
        <IconButton style={{ fontSize: "16px", color: "#1A96F0" }}>
          Reset
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          placeholder="Search by Location"
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
            endAdornment: (
              <IconButton>
                <FilterListIcon />
              </IconButton>
            ),
          }}
        />
      </Box>
      <Box mb={2}>
        <Typography
          variant="body1"
          gutterBottom
          display="flex"
          alignItems="center"
        >
          <BorderColorIcon fontSize="small" sx={{ marginRight: 1 }} />
          Select Category
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography
          variant="body1"
          gutterBottom
          display="flex"
          alignItems="center"
        >
          <LaunchIcon fontSize="medium" sx={{ marginRight: 1 }} />
          Customize Radius
        </Typography>
        <Slider
          value={radius}
          onChange={handleRadiusChange}
          step={1}
          min={1}
          max={50}
          valueLabelDisplay="auto"
          marks={marks}
          sx={{
            color: "#ff2557",
          }}
        />
        <Divider />
      </Box>
      <Typography variant="h6" gutterBottom>
        Trending 🔥
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Explore local hotspots and global buzz with real-time updates. See posts
        from the past 60 minutes to stay in sync with the world's pulse.
      </Typography>
      <List>
        {trendingLocations && trendingLocations.length > 0 ? (
          trendingLocations.map((location, index) => (
            <ListItem key={index} disableGutters style={{ padding: 0 }}>
              <ListItemText
                primary={
                  <span style={{ fontSize: "0.8rem" }}>{location.name}</span>
                }
                secondary={
                  <span style={{ fontSize: "0.8rem" }}>
                    {location.distance}
                  </span>
                }
              />
            </ListItem>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            No trending locations available.
          </Typography>
        )}
      </List>

      <Divider />

      <List>
        {trendLocationPosts && trendLocationPosts.length > 0 ? (
          trendLocationPosts.map((post, index) => (
            <ListItem key={index} disableGutters style={{ padding: 0 }}>
              <ListItemText
                primary={
                  <span style={{ fontSize: "0.8rem" }}>{post.name}</span>
                }
                secondary={
                  <span
                    style={{ fontSize: "0.8rem" }}
                  >{`${post.messages_count} Posts, ${post.evidences_count} Stories`}</span>
                }
              />
            </ListItem>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            No trending posts available.
          </Typography>
        )}
      </List>
    </Box>
  );
};

export default LiveFeedFilter;
